.jsa-modal-open {
  overflow: hidden;
}

.cmp-region-modal {
  overflow: scroll;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: map-get($backgrounds, sepia-bg);
  z-index: map-get($z, modal);
  display: none;
  flex-direction: column;

  .cmp-wrapper {
    @include sw-container($fluid: true);

    @include media('<=desktop'){
      max-width: 95%;
    }
  }
}

.jsa-region-modal--open {
  display: flex;
}

.cmp-region-modal-header {
  background: map-get($backgrounds, light-bg);
  padding: rem(40px 0 28px);

  @include media('>=desktop') {
    padding: rem(50px 0);
  }
}

h1.cmp-region-modal-header__title {
  font-weight: map-get($font-weights, light);
  font-size: rem(31px);
  margin-bottom: rem(15px);

  @include media('>=desktop') {
    font-size: rem(51px);
    margin-bottom: rem(20px);
  }
}

.cmp-region-modal-header__description {
  font-size: rem(14px);
  font-weight: map-get($font-weights, normal);
}

.cmp-region-modal-header__close-button {
  position: absolute;
  top: rem(16px);
  right: rem(16px);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cmp-region-modal-header__close-button-text {
  font-weight: map-get($font-weights, bold);
  display: none;
  font-size: rem(10px);
  margin-right: rem(10px);
  text-transform: uppercase;

  @include media('>=desktop') {
    display: inline-block;
  }
}

.cmp-region-modal-header__close-button-icon {
  width: rem(14px);
  height: rem(14px);
  stroke: currentColor;

  @include media('>=desktop') {
    width: rem(11px);
    height: rem(11px);
  }
}

.cmp-region-modal-regions {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.cmp-region-modal-regions__countries-list {
  padding-left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  display: none;

  @include media('>=desktop') {
    padding-left: rem(20px);
  }
}

.cmp-region-modal-body {
  padding: rem(16px 0 45px);
  position: relative;
  flex-grow: 1;

  @include media('>=desktop') {
    padding: rem(45px 0);
  }
}

.cmp-region-modal-body__wrapper {
  display: flex;
  flex-flow: column wrap;
}

.cmp-region-modal-footer {
  padding: rem(30 0);
  text-align: center;

  @include media('>=desktop') {
    display: none;
  }
}

.jsa-region-modal__child--open .cmp-region-modal-regions__countries-list {
  position: relative;
  left: 0;
  width: 100%;
  top: 0;
  display: block;

  @include media('>=desktop') {
    position: absolute;
    left: 25%;
    width: 75%;
  }
}

.cmp-region-modal-regions__item-button {
  background: none;
  border: 0;
  padding: rem(10px);
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
  display: flex;
  align-items: center;
  font-size: rem(14px);
  font-weight: map-get($font-weights, normal);
  order: -1;

  &:hover {
    background: rgba(0, 0, 0, 0.09);
  }
}

.jsa-region-modal__child--open > .cmp-region-modal-regions__item-button {
  font-size: rem(20px);
  margin-bottom: rem(16px);
  background: none;

  @include media('>=desktop') {
    font-size: rem(14px);
    background: rgba(0, 0, 0, 0.09);
    margin-bottom: 0;
  }
}

.cmp-region-modal-regions__item {
  flex-direction: column;
}

.cmp-region-modal-regions > .cmp-region-modal-regions__item {
  max-width: 100%;
  width: 100%;

  @include media('>=desktop') {
    max-width: 25%;
  }
}

.cmp-region-modal-regions__item-icon {
  margin-right: rem(10px);
}

.cmp-region-modal-regions__countries-list > li {
  @include media('>=desktop') {
    max-width: 33.333333%;
  }
}

// The country experience fragment content – no custom style group etc added.
.cmp-region-modal-regions__country-content {
  display: none;

  & .title {
    margin-top: rem(40px);

    &:first-child {
      margin-top: 0;
    }
  }

  & .cmp-title__text {
    @include ui-heading4;
  }

  & .linklist {
    font-weight: map-get($font-weights, normal);
    font-size: rem(14px);
  }

  & .cmp-linklist {
    flex-flow: column wrap;
    display: flex;

    @include media('>=desktop') {
      flex-flow: row wrap;
    }
  }

  & .cmp-linklist__links {
    min-width: 50%;

    @include media('>=desktop') {
      margin-left: auto;
    }
  }

  & .cmp-linklist__links-list {
    display: flex;
    list-style: none;
    padding-left: inherit;
  }

  & .cmp-linklist__item {
    margin-right: rem(15px);

    &:first-child {
      @include media('>=desktop') {
        margin-left: auto;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  & .cmp-linklist__header--text,
  & .cmp-linklist__item--link {
    color: inherit;
    font-size: rem(16px);

    @include media('>=desktop') {
      font-size: rem(14px);
    }
  }

  & .cmp-linklist__item--link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.regionselector .cmp-linklist__item--link {
  & > .sw-icon {
    display: inline;
    height: 10px;
    width: 10px;
    margin-left: rem(4px);
    transition: transform map-get($transitions, default);
  }

  &:hover > .sw-icon {
    transform: translateX(rem(4px));
  }
}

.jsa-region-modal__child--open > .cmp-region-modal-regions__country-content {
  position: relative;
  width: 100%;
  left: 0;
  padding-left: 0;
  display: block;

  @include media('>=desktop') {
    position: absolute;
    left: 33.33333%;
    width: 66.66666%;
    padding-left: rem(40px);
    top: 0;
  }
}

.cmp-region-modal-regions__col {
  &::before {
    @include media('>=desktop') {
      content: attr(data-title);
      font-size: rem(36px);
      font-weight: 600;
      margin-bottom: rem(32px);
      display: block;
    }
  }
}

.jsa-region-modal__child--open
  > .cmp-region-modal-regions__item-button
  > .cmp-region-modal-regions__item-icon {
  display: none;

  @include media('>=desktop') {
    display: block;
  }
}

.jsa-region-modal__child--open
  > .cmp-region-modal-regions__item-button::before {
  content: '';
  display: block;
  margin-right: rem(5px);

  @include icon-carat-left();

  @include media('>=desktop') {
    display: none;
  }
}

.jsa-region-modal-col--open > .cmp-region-modal-regions__item {
  display: none;

  @include media('>=desktop') {
    display: block;
  }
}

.jsa-region-modal-col--open > .jsa-region-modal__child--open {
  display: flex;
}

// This element selector is needed to selectively hide the parent title if the child is open.
ul.jsa-region-modal-col--open + .cmp-region-modal-regions__item-button {
  display: none;

  @include media('>=desktop') {
    display: block;
  }
}
