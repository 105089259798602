.cmp-out-of-region {
  background-color: map-get($backgrounds, sepia-bg);
  clip-path: polygon(-100vw 0, 100vw 0, 100vw 100%, -100vw 100%);
  transition: max-height 0.8s ease-in-out;
  position: relative;

  &::before {
    @include fullbleed();
    content: '';
    background-color: inherit;
  }
}

.cmp-out-of-region--enabled {
  max-height: 0;
}

.jsa-cmp-out-of-region--open {
  max-height: rem(400px);
}

.cmp-out-of-region__wrapper {
  display: flex;
  flex-direction: column;
  padding: rem(20px 0);

  @include media('>=desktop') {
    flex-direction: row;
    padding: rem(30px 0);
  }
}

.cmp-out-of-region__wrapper-item {
  @include media('>=desktop') {
    width: 50%;
  }
}

.cmp-out-of-region__wrapper-item:first-child {
  @include media('>=desktop') {
    padding-right: rem(100px);
  }
}

.cmp-out-of-region__description {
  font-size: rem(14px);
  line-height: rem(16px);
  margin-bottom: rem(30px);
  font-weight: map-get($font-weights, normal);
  letter-spacing: normal;

  @include media('>=desktop') {
    font-size: rem(16px);
    line-height: rem(18px);
    margin-bottom: 0;
  }
}

.cmp-out-of-region__link {
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-weight: map-get($font-weights, normal);
  font-size: rem(16px);
  text-align: left;
}

// This additional scoping is unfortunate but unnecessary because of the .global-gateway {element} default styles that are conflicting.
button.cmp-out-of-region__button {
  margin-top: 0;
  margin-bottom: rem(10px);
  cursor: pointer;

  @include media('>=desktop') {
    margin-bottom: rem(18px);
  }
}

h2.cmp-out-of-region__subtitle {
  font-size: rem(16px);
  line-height: rem(18px);
  letter-spacing: normal;
  margin-bottom: rem(27px);
  font-weight: map-get($font-weights, normal);

  @include media('>=desktop') {
    font-size: rem(20px);
    line-height: rem(24px);
    margin-bottom: rem(34px);
  }
}

h1.cmp-out-of-region__title {
  font-size: rem(20px);
  line-height: rem(24px);
  letter-spacing: normal;
  margin-bottom: rem(20px);
  font-weight: map-get($font-weights, normal);
}
